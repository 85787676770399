import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './CartModal.st.css';
import React from 'react';
import {
  Button,
  Dialog,
  ButtonPriority,
  Text,
  TextTypography,
  Divider,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa';
import { CartFlow } from '../../../../types/types';
import { useFormActions } from '../../Hooks/useFormActions';

const CartModal = ({ cartModalStatus }: { cartModalStatus: boolean }) => {
  const { t } = useTranslation();
  const { onDialogClose, onCartCTAClick } = useFormActions();

  return (
    <Dialog isOpen={cartModalStatus} onClose={() => onDialogClose()}>
      <div className={st(classes.root)}>
        <Text typography={TextTypography.largeTitle}>
          {t('app.booking-details.cart-modal.title')}
        </Text>
        <div className={classes.content}>
          <Text typography={TextTypography.listText} tagName="div">
            <div>{t('app.booking-details.cart-modal.content')}</div>
          </Text>
        </div>
        <Divider />
        <div className={classes.ctaContainer}>
          <Button
            upgrade
            priority={ButtonPriority.basicSecondary}
            className={classes.ctaButton}
            onClick={() => onCartCTAClick(CartFlow.CHECKOUT)}
          >
            {t('app.booking-details.cart-modal.cta.checkout.label')}
          </Button>
          <Button
            upgrade
            className={classes.ctaButton}
            onClick={async () => {
              await onCartCTAClick(CartFlow.ADD_TO_CART);
              window.history.back();
            }}
          >
            {t('app.booking-details.cart-modal.cta.add-more-sessions.label')}
          </Button>
        </div>
        <TextButton
          priority={TextButtonPriority.link}
          onClick={async () => {
            await onCartCTAClick();
          }}
        >
          {t('app.booking-details.cart-modal.link.continue-browsing.label')}
        </TextButton>
      </div>
    </Dialog>
  );
};

export default CartModal;
